.settingsmaindiv {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.set-secBs {
  width: 70%;
  flex: 1;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

/* .bannerimage{
    width: %;
  } */
/* .set-secBs form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  } */

.banner-container {
  width: 90%;
}

.banner-container form {
  border: 1px solid var(--font);
  padding: 4% 5%;
  border-radius: 5px;

}

.banner-form {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.banner-card {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-direction: column;
  border: 1px solid #832729;
  /* Added */
  padding: 4%;
  /* Added */
  border-radius: 5px
}

.inputFieldGrid {
  width: 98% !important;
  padding-left: 3px !important;
  height: 2.2rem;
  font-size: 15px !important;
  border: 1px solid #832729 !important;
  border-radius: 5px;
  outline: none !important;
  margin-top: 2%;
}

.set-secBs label {
  font-weight: 600;
  color: #832729;
}

.set-secBs input,
.set-secBs select,
.set-secBs textarea {
  width: 100%;
  padding: 0;
  font-size: 15px;
  border: none;
  border-bottom: 1px solid #832729;
  /* background-color: var(--background); */
  outline: none;
}

/* .set-secBs select option:nth-child(even){
    background-color: var(--background);
  } */

.set-secBs button {
  display: block;
  margin-top: 3%;
  padding: 10px 20px;
  background-color: #832729;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.set-secBs button:hover {
  background-color: var(--hover)
}

.set-secBs button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.MuiDrawer-paperAnchorLeft {
  top: 82px !important;
}

.MuiDrawer-paper{
  background-color: var(--background) !important;
  color: var(--font) !important;
}

@media screen and (max-width:1024px) {
  .settingsmaindiv .set-secA {
    padding-bottom: 16% !important;
  }

  .set-secBs {
    padding-top: 3%;
  }
}


@media screen and (max-width: 768px) {
  .set-secBs {
    justify-content: center;
    padding: 0 3%;
    width: 93%;
  }

  .set-secBs form {
    grid-template-columns: 1fr;
    /* Display one column on smaller screens */
  }

  .banner-container {
    width: 100%;
  }
}


@media screen and (max-width:600px) {
  .settingsmaindiv {
    display: block !important;
    padding-inline: 0;
  }

  .set-secBs {
    margin-bottom: 4%;
  }



  .banner-container {
    width: 100%;
  }

  .banner-card {
    padding: 3%;
  }
}

@media screen and (max-width:375px) {

  .set-secBs {
    padding: 0;
  }

  .set-secBs form {
    max-width: 100%;
  }
}