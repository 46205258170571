/* popup styles */
.overlay-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 2%;
  gap: 1rem;
  position: relative;
}

.container h3 {
  margin: 0;
}

.popup-header {
  margin: 0;
}

.popup-text {
  font-weight: 400;
}

.close-button {
  position: absolute;

  cursor: pointer;
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: auto;

}

.popup-button {
  outline: 0;
  border: 0;
  padding: 10% 70%;
  border-radius: 5px;
  transition: 500ms ease;
  font-size: 0.9rem;
  cursor: pointer;
}

.popup-button:nth-child(2) {
  background-color: var(--font);
  color: var(--background);
  border: 1px solid var(--font);
}

.popup-button:nth-child(1) {
  border: 1px solid var(--font);
  color: var(--font);
  background: white;
}

.popup-button:nth-child(1):hover {
  background-color: var(--background);
}
.popup-button:nth-child(2):hover {
  background: var(--hover);
}

.ornamentpopmain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 8%;
  gap: 1rem;
}

.field-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
  /* justify-content: center; */
}

.field-container-select {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}

.select-field-1 {
  width: 15rem;
  height: 2.3rem !important;
}
.selectpurpose {
  /* width: 15rem; */
  height: 2rem;
  margin-top: 2.4%;
  border: none;
  border-radius: 5px;
}
.datevaluatuion {
  /* width: 15rem; */
  height: 2rem;
  margin-top: 5px;
  border: 1px solid var(--grey);
  border-radius: 5px;
  padding-left: 2% !important;
}

.section-ornaments {
  display: flex;
  justify-content: space-between;
}
.ornamentpop {
  height: auto;
  width: 30rem;
  position: relative;
  margin: 2% 4%;
  border: 1px solid var(--font);
  border-radius: 5px;
}
label {
  font-size: 15px;
}

.val-det-input {
  width: 11rem;
  height: 1.6rem;
  border-radius: 0;
  border: 1px solid var(--grey);
  /* border-radius: 5px; */
  margin-top: 1%;
}
.val-det-input:focus {
  border: 1px solid var(--font);
}
.val-det-btn {
  font-size: 0.9rem;
  color: var(--background);
  background-color: var(--font);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 2%;
  margin: 5%;
  transition: 500ms ease;
}

.val-det-btn:hover {
  background-color: var(--hover);
}
.val-det-btn2 {
  font-size: 1rem;
  color: var(--background);
  background-color: var(--font);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 5%;
  margin: 3%;
}

.ornamentsdisplay {
  padding-block: 3%;
  width: 60%;
}

.val-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.navigation-button-container {
  padding-bottom: 1%;
}

.form-header {
  font-size: 1.2rem;
  color: var(--font);
  padding: 0% 8%;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


@media screen and (max-width: 1200px) {
  h2 {
    text-align: center;
  }
  .section-ornaments {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2%;
    gap: 1rem;
  }

  input {
    padding: 0;
  }

  /* .val-det-btn {
    padding: 1.5%;
  } */
  .ornamentpop {
    height: auto;
    position: relative;
    border: 1px solid var(--font);
    border-radius: 8px;
  }

  .ornamentsdisplay {
    width: 100%;
  }

  .tablecontainer2 {
    width: 100%;
  }
  .tablecontainer2 table {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .ornamentpop {
    padding: 0%;
  }
  .container {
    width: 40%;
  }

  .ornamentpop {
    margin: 0;
  }

  .ornamentpop section {
    height: 2.3rem;
  }

  .val-det-input {
    height: 2.2rem;
  }

  .section-ornaments {
    padding: 2%;
  }

  .input-cont {
    display: flex;
    flex-direction: column;
  }

  /* .val-det-btn {
    width: 15%;
    margin: 2rem;
  } */

  .navigation-button-container {
    padding-bottom: 3%;
    height: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 40%;
  }

  .popup-button {
    padding: 10% 60%;
  }

  .popup-header {
    font-size: 1.5rem;
  }
  .popup-text {
    font-size: 0.9rem;
  }

  .ornamentpop select {
    width: 10rem !important;
    height: 2.1rem;
  }

  .section-ornaments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
  }

  .val-det-input {
    width: 10rem;
    height: 2rem;
  }

  /* .val-det-btn {
    margin: 2rem;
    width: 12%;
    padding: 1%;
  } */

  .cus-det-btn {
    width: 10%;
  }
}

@media screen and (max-width: 700px) {
  .section-ornaments {
    padding-inline: 3%;
  }
  .tablecontainer2 {
    overflow: scroll;
    padding: 0;
  }

  th {
    padding: 3px 5px !important;
  }
}

@media screen and (max-width: 425px) {
  .input-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .val-container{
    flex-direction: column;
  }

  .ornamentpop input {
    width: unset;
  }

  .ornamentpop {
    width: 100%;
  }

  .ornamentpop select {
    width: 100% !important;
  }

  .ornamentpop h2 {
    margin: 2% 0;
    padding: 4%;
  }

  .cus-det-save {
    width: 20%;
  }

  .navigation-button-container {
    height: 3rem;
  }

  .section-ornaments {
    padding-inline: 5%;
  }

  .field-container-select {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .select-field-1 {
    width: 100%;
  }

  .divdiv {
    width: 100%;
  }

  .field-container-select {
    align-items: unset;
  }

  .val-det-btn {
    margin: 1.5rem;
    width: 25%;
    padding: 3%;
  }

}

@media screen and (max-width: 375px) {
  .ornamentpop {
    padding-inline: 2%;
  }

  .ornamentpop select {
    height: 2.1rem;
  }

  .val-det-btn {
    margin: 1rems;
  }

  .ornamentpop h2 {
    padding: 0;
  }
}

@media screen and (max-width: 320px) {
  .val-det-btn {
    width: 30%;
  }

  .section-ornaments {
    padding-inline: 2%;
  }

  .navigation-button-container {
    height: 2rem;
  }
  .cus-det-save {
    margin: 0;
    padding: 0;
  }


  .ornamentpop select {
    width: 100% !important;
  }
}


@media print{
  .VR{
    page-break-before: always;
  }
}