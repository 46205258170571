/* default */
:root {
    --background: #f2e9e9;
    --font: #832729;
    --hover:#b40808;
    /* --hover:#831919; */
    --grey:#ccc;
  }
  /* --background: #f2e9e9;
  --font: #832729; */


/* theme 1 */
  /* :root {
    --background: #f2e9e9;
    --font: #832729;
    --accent:
  } */