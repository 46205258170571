/* Default styles */
.overlay-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingsmaindiv {
  position: relative;
  display: flex;
}

.set-secA {
  min-width: 20%;
  height: auto;
  background-color: var(--font);
  padding-bottom:7% !important;
  display: flex;
}

.container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settingsmaindiv .set-secA section .sidebar-head {
  color: var(--background) !important;
  /* margin-top: 0; */
  padding-bottom: 2%;
  text-align: left;
  padding-left: 10%;
  margin-left: 9%;
}

.sidebar-comp {
  margin: 0;
  color: var(--background);
  padding: 18px !important;
  cursor: pointer;
  transition: ease 500ms;
  /* cursor: pointer; */
}

/* .sidebar-comp:hover {
    background: var(--background);
    color: var(--font);
    font-weight: bold;
    cursor: pointer;
  } */

.sidebar-comp:hover {
  background: var(--hover);
  cursor: pointer !important;
}

.linkrout {
  text-decoration: none;
  text-align: left;
  /* cursor: pointer; */
}

.set-secB {
  display: flex;
  width: 80%;
  flex-direction: row !important;
  justify-content: center;
  align-items: flex-start;
  padding: 5% !important;
}

.ornaments-head {
  color: var(--font);
  /* padding-top: 5%;
  padding-bottom: 5%; */
}

.add-ornament-btn {
  color: var(--background);
  background-color: var(--font);
  border: none;
  outline: none;
  width: max-content;
  height: 10%;
  cursor: pointer;
  font-size: 0.9rem;
  transition: 500ms ease;
  border-radius: 5px;
  margin-top: 0% !important;
  margin-inline: unset !important;
}

.add-ornament-btn-1{
  color: var(--background);
  background-color: var(--font);
  border: none;
  outline: none;
  width: max-content;
  height: 10%;
  cursor: pointer;
  font-size: 0.9rem;
  transition: 500ms ease;
  border-radius: 5px;
  margin-inline: unset !important;
  margin-top: 38% !important;
}

.add-ornament-btn:hover {
  background: var(--hover);
}

.manageornaments {
  height: fit-content;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.manageornaments-2 {
  display: flex;
  flex-direction: column;
}



.ornament-form-container {
  display: flex;
  justify-content: center;
}

.ornamentform {
  position: relative;
  height: auto;
  background-color: white;
  height: 30%;
  width: 25%;
  border: 1px solid var(--font);
  border-radius: 8px;
}

.close-button{
  color: var(--font);
  position: absolute;
  right: 2%;
  top: 2%;
  font-size: 1.2rem;
  cursor: pointer;
}

.ornamentform form {
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* gap: 1px; */
  min-height: 100%;
}

.ornamentinput {
  border: 1px solid var(--grey);
  height: 2rem;
  width: 70%;
  border-radius: 5px;
}

.ornamentinput:focus {
  border: 1px solid var(--font);
}

.jewellerform-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 30%;
  padding: 2%;
  border: 1px solid var(--font);
  position: relative;
  background-color:white;
  border-radius: 5px ;
}

.jewelllermasterinput {
  border: 0;
  padding-left: 1%;
  height: 2rem;
  width: 100%;
  border-bottom: 1px solid var(--grey);
}
.jewelllermasterinput:focus{
  border-bottom: 1px solid var(--font);
}

.usermasterform {
  height: auto;
  width: 50%;
  margin: 2%;
  padding: 2%;
  border: 1px solid var(--font);
  border-radius: 8px;
}
.set-secB .first {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  height: fit-content;
  align-items: center;
  gap: 2rem;
}
.set-secB .first .searchtable {
  width: 20rem;
  height: 2rem;
  border: 1px solid var(--font);
  border-radius: 8px;
  padding: 2%;
}
/* CSS for the Pagination component */
.pagination {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 20px 0;
}

.pagination button {
  padding: 1px 5px;
  margin: 0 4px;
  border: 0.1px solid var(--font);
  background-color: var(--background);
  color: var(--font);
  cursor: pointer;
  transition: 500ms ease;
  border-radius: 5px;
}

.pagination button:hover {
  /* border: 1px solid var(--font); */
  background-color: var(--font);
  color: var(--background);
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.pagination button:disabled:hover {
  background-color: var(--background);
  color: var(--font);
  border: 1px solid var(--font);
}

.pagination input[type="number"] {
  width: 24px;
  padding: 1px;
  text-align: center;
  border: 0.1px solid #927b7b;
  background-color: var(--background);
  color: var(--font);
  border-radius: 5px;
}

.pagination input[type="number"]:focus {
  outline: none;
  /* background-color: #fff; */
  box-shadow: 0 0 3px #aaa;
  border: 1px solid var(--font);
}

.addornamentbtn {
  outline: 0;
  border: 0;
  background: var(--font);
  color: var(--background);
  padding: 10px 15px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: 500ms ease;
  border-radius: 5px;
}

.addornamentbtn:hover {
  background: var(--hover);
}
.pagination span {
  color: var(--font);
  margin: 0 4px;
}

.ornaments-table{
  width: 100% !important;
}

.ornaments-table thead {
  color: var(--font);
}

.ornaments-table thead th {
  border: 1px solid var(--font);
}

.ornaments-table td {
  border: 1px solid var(--font);
  text-align: center;
}

.ornamentinput{
    text-align: center;
}
.ornamentinput::placeholder{
    text-align: center;
}

/* Media queries */
@media screen and (max-width: 1024px) {
  .set-secA {
    padding-bottom: 8%;
  }

  .set-secB {
    padding: 10% 2%;
  }
}

@media (max-width: 959px){
  .add-ornament-btn-1{
    margin-top: 0% !important;
  }
  .filterSearch{
    width: 50% !important;
    display: flex !important;
    flex-direction: row !important;
  }
}

@media (max-width: 768px) {

  .jewellerform-container{
    width: 40%;
  }
  .set-secA {
    min-width: 25%;
    padding-bottom: 0;
  }

  .set-secB {
    width: 100%;
    padding: 0;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .first {
    gap: 0;
  }

  .ornaments-head {
    margin: 0;
    padding: 3% 0 0 0;
  }

 

  .ornamentform {
    margin-bottom: 2rem;
    width: 50%;
  }

  .addornamentbtn {
    margin-left: 5%;
    width: 40%;
  }

  .ornamentshead {
    padding-left: 5%;
  }
}

@media screen and (max-width: 480px) {
  .jewellerform-container{
    max-width: unset !important;
    width: 65% !important;
  }

  .filterSearch{
    width: 90% !important;
    display: flex !important;
    flex-direction: row !important;
    padding: 0% 5% !important;
  }

  .set-secBs{
    width: 94%;
  }

  .set-secB{
    flex-direction: column !important;
  }

  h2{
    margin-bottom: 3% !important;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .addornamentbtn{
    width: unset !important;
  }

  .first {
    position: relative;
  }

  .side-bar-icon {
    position: absolute;
    left: 4%;
    top: 8%;
    font-size: 1.2rem;
    color: var(--font);
  }

  .sidebar-mobile {
    position: relative;
    max-width: 60%;
    height: auto;
    background-color: var(--font);
    z-index: 20;
    height: 100%;
    animation: sidebarAnimation 100ms ease-in-out;
  }

  @keyframes sidebarAnimation {
    from {
      width: 0%;
    }
  
    to {
     width: 100%;
    }
  }
  @keyframes text {
    from {
      opacity: 0;
    }
  
    to {
     opacity: 1;
    }
  }

  /* .linkrout{
    animation: text 1000ms ease-in-out;
  } */

  .close-sidebar-icon {
    font-size: 20px;
    position: absolute;
    right: 5%;
    top: 5%;
    color: var(--background);
  }

  .ornamentform{
    width: 70%;
  }

  .settingsmaindiv-mobile {
    padding-bottom: 26%;
    display: block;
  }

  .active {
    display: block !important;
  }

  .addornamentbtn{
    margin: 0;
  }

  .pagination{
    justify-content: center;
  }
  
}

@media screen and (max-width: 375px) {
  .jewellerform-container{
    width: 70% !important;
  }
  .set-secBs{
    width: 100%;
  }
}



@media screen and (max-width: 320px) {
    .tablecontainer{
        padding-inline:3%
    }

    .MuiButtonBase-root{
      padding: 0% 0% !important;
    }

    .side-bar-icon{
        top: 6%;
    }

    .jewellerform-container{
      width: 80% !important;
    }

   
  
}
