.footer {
    display: flex;
    justify-content: space-between;
    background-color: var(--font);
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 0% 4.5%;
}

.footer_div .footertext  {
    text-align: center  !important;
    color: var(--background) !important;
    padding-block: 1% !important;
}

.footer_div .termpolicy-text{
    padding: 0 !important;
}

.termpolicy {
    color: var(--background);
    cursor: pointer;
    text-decoration: none;
}
@media only screen and (max-width: 600px) {
    .footer_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0% 0%;
    }
  }
  