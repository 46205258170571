
.blog_data_container{
    width: 95%;
    margin: 0 auto;
}
.blog-posts-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}


.blog-posts-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.add_blog {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: var(--font);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.add_blog a {
    color: white;
}

.add_blog :hover {
    background-color: var(--hover);
}

.add-blog-link {
    text-decoration: none;
    color: white;
}

/* List of blog posts */
.blog-posts-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

/* Individual blog post card styles */
.blog-post-card {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s;
}

.blog-post-card:hover {
    transform: scale(1.02);
}

/* Image styles */
.blog-post-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.blog-post-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Content styles */
.blog-post-content {
    padding: 15px;
}

.blog-post-title {
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
}

.blog-post-description {
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
}

.blog-post-status {
    font-weight: bold;
    color: #28a745; 
}
 




.blog-start {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.blog-start .btn{
    height: 2.5rem;
    width: 26%;
    font-size: 1rem;
    border: none;
    /* border-radius: 5px; */
    cursor: pointer;
    margin: 3%;

}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--font); 
}

.top {
   
    justify-content: space-between;
    margin-bottom: 20px;
}

.btns {
    display: flex;
    gap: 10px;
}



.blog-start section {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.right-side {
    flex: 1;
}

.sec {
    margin-bottom: 20px;
}

.input-field {
    position: relative;
}

.input-field input,
.input-field textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.input-field textarea {
    resize: none;
}

.input-field label {
    position: absolute;
    top: -12px;
    left: 10px;
    font-size: 14px;
    color: #999;
    transition: 0.2s ease;
}

.input-field input:focus + label,
.input-field textarea:focus + label,
.input-field input:not(:placeholder-shown) + label,
.input-field textarea:not(:placeholder-shown) + label {
    top: -20px;
    font-size: 12px;
    color: var(--font); /* Use the CSS variable for color */
}

.materialize-textarea {
    height: auto; /* Ensure height is adjustable */
}

.sec.add-blog {
    flex: 1;
}


@media (max-width: 768px) {
    section {
        flex-direction: column;
    }

    .blog-start section {
  flex-direction: column};

}

@media (max-width: 768px) {
    .btns {
       display: block;
    }

}