.privacy-start{
  width: 90%;
  margin: 0 auto;
  padding-bottom: 5%;
  text-align: justify;
}

.privacy-policy-cont {
  padding: 3% 5% 7% 5%;
}

.privacy-policy-cont .header-text {
  color: var(--font);
  text-align: left;
}

.privacy-policy-cont .subheader-text {
  text-align: left;
}

.privacy-policy-cont .policy-text {
  color: var(--font);
  text-align: left;
}


@media screen and (max-width:500) {
  .privacy-start{
    padding-bottom: 30%;
  }
}