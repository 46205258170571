   .aboutsection {
     padding: 5%;
   }

   .heading2ab-us {
     color: var(--font);
     padding-bottom: 2%;
   }

   .mainhead {
     text-align: center;
     justify-content: center;
     display: flex;
     margin-top: 10vh;
     font-size: 3rem;
     font-weight: 700;
   }

   .search-bar-main {
     padding-top: 1%;
     height: 20vh;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
   }
   
   .certificate-mobile {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 5px;
   }

   .search-bar-container {
     width: 35%;
     display: flex;
     justify-content: space-between;
     margin-top: 0vh;
     border-radius: 5px;
     justify-content: center;
     align-items: center;
     gap: 5%;
     padding: 10px;
     border: 1px solid var(--font);
   }

   .homeTable{
    width: 80%;
    padding: 4% 0%;
    overflow: hidden;
   }

   .formdetails-1 {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    display: flex;
    /* flex-direction: column; */
    gap: 0.8rem;
    color: var(--font);
    width: 90%;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

   .search-input {
     margin-right: 5px;
     padding: 5px;
     border: 1px solid #ccc;
     border-radius: 5px;
     width: 245px;
     height: 5vh; 
   }

   .search-input:focus {
     border: 1px solid var(--font);
   }

   .search-button {
     padding: 14px 10px;
     font-size: 0.9rem;
     background-color: #832729;
     color: white;
     border: none;
     border-radius: 5px;
     cursor: pointer;
   }

   .search-button:hover {
     /* background-color:white;
    font-weight: bold;
    color: var(--font);
    border: 1px solid var(--font); */
     background-color: #631e20;
     transition: ease 400ms;
   }


   /* .aboutsection{
    padding: 5%;
}

.heading2ab-us{
    color: var(--font);
    padding-bottom: 2%;
} */

.check-valuer{
    padding: 14px 10px;
    color: #ffffff;
    background: var(--font);
    border-radius: 5px;
    border: 1px solid var(--font);
    cursor: pointer;
  
}

   .card-container {
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
     grid-gap: 30px;
     padding: 30px;
     margin-bottom: 5%;
     padding-top: 57px;
   }

   /* .card {
  margin-bottom: 10vh;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  border: 1px solid #752828fa;
  overflow: hidden;
} */
   /* 


.card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-content {
  padding: 16px;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.card-text {
  font-size: 14px;
  color: #555555;
} */

   .card-container {
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
     grid-gap: 20px;
     justify-content: center;
   }

   .card {
     /* margin-bottom: 10vh; */
     background-color: #fff;
     border-radius: 8px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
     overflow: hidden;
     border: 1px solid var(--hover);

   }

   .card:hover {
     box-shadow: 0px 5px 20px 0px var(--hover);
     transition: all ease 500ms;
     cursor: pointer;
   }

   .card-image {
     width: 100%;
     height: 200px;
     object-fit: cover;
   }

   .card-content {
     padding: 16px;
   }

   .card-heading {
     font-size: 20px;
     font-weight: bold;
     margin-bottom: 8px;
     color: var(--font) !important;
   }

   .card-container .card .card-content .card-text {
     font-size: 14px;
     color: var(--font) !important;
   }

   /* Add animation for image slider */
   /* .slider-container {
  animation: slideImage 5s infinite;
} */

   @keyframes slideImage {
     0% {
       opacity: 0;
     }

     20% {
       opacity: 1;
     }

     33.33% {
       opacity: 1;
     }

     100% {
       opacity: 0;
     }
   }

   .ad {
     font-size: 2rem;
     font-weight: 700;
     font-family: 'Nunito', sans-serif;

   }
   .home-blog-card-container {
    display: flex; 
    overflow: hidden;
    width: 100%; 
    position: relative; 
  }
  
  .home-blog-card-wrapper {
    display: flex; 
    animation: scroll 20s linear infinite; 
  }
  
  .home-blog-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--hover);
    text-align: justify;
    margin: 0 15px; 
    flex: 0 0 33.33%; 
    scroll-snap-align: start;
  }

  .home-blog-card-wrapper:hover {
    animation-play-state: paused;
  }
  

  
  
  @keyframes scroll {
    0% {
      transform: translateX(0); 
    }

    100% {
      transform: translateX(-100%); 
    }
  }
  

  .home-single-blog{
    width: 95%;
    margin: 0 auto;
  }
.home-blog-post-image{
  width: 100%;
  height: 300px;
  padding-bottom: 30px;
 
}

.home-blog-post-image img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width:768px) {
  .home-single-blog .single-blog-title{
  font-size: 2rem;
  }}
  
   
   @media screen and (max-width:1024px) {
    .search-bar-container {
      width: 46%;
    }
   }


   @media screen and (max-width:768px) {
     .search-bar-main {
       padding-top: 3%;
     }
     .formdetails-1{
      flex-direction: column;
     }

     .homeTable{
      width: 95%;
      padding: 15% 0%;
     }

     .search-bar-container{
      display: flex;
      flex-direction: column;
      width: 99%;
      gap: 16px;
     }

     .card-container {
       justify-content: center;
       grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
     }

   }
   @media screen and (max-width:500px){
    .search-bar-container{
      width: 90%;
    }
    .card-container{
      padding-top:95px;
    }
    .home-blog-post-image{
      padding-bottom: 10px;
    }
   }

   @media screen and (max-width:320px) {
     .card-container {
       grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
     }

     .search-bar-container{
      display: flex;
      flex-direction: column;
      width: 95%;
      gap: 16px;
     }
   }