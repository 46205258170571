.topbar {
  max-width: 100%;
  height: auto;
  background-color: var(--background);
  display: flex;
  align-items: center;
  padding: 0% 4.5%;
  justify-content: space-between;
  bottom: 0px;
}



.logo1 {
  height: 5rem;
  max-width: 5rem;
  position: relative;
  left: 1%;
}

.qrscan {
  display: none;
}

.personicon {
  position: relative;
  height: 1.8rem;
  cursor: pointer;
  border-radius: 50%;
}

.login {
  color: var(--font) !important;
}

.signup {
  color: white !important;
  background-color: var(--font) !important;
}

.rqotp {
  color: white !important;
  background-color: var(--font) !important;
}



/*Popup*/

.close-popup {
  color: var(--background) !important;
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 5%;
}

.top-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}



.sectiona {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sectiona h3,
p {
  margin: 0 !important;
}

.profile-img {
  width: 8rem;
  border-radius: 50%;
  object-fit: contain;
}

.popup1 {
  box-sizing: border-box;
  width: 15%;
  position: absolute;
  right: 0px;
  padding: 1rem;
  background-color: var(--font);
  border-radius: 10px;
  border-style: initial;
  box-shadow: 0px 0px 1px 0.5px var(--font);
  margin-right: 1rem;
  margin-top: 1rem;
  z-index: 99;
}


.linkRoute {
  text-decoration: none;
}

.ub-mt_12vmin {
  margin-top: 5vmin !important;
  height: auto;
  width: 45rem !important;
}

.ub-dspl_flex {
  z-index: 9999 !important;
  border-radius: 0 !important;
}

a {
  text-decoration: none;
}

.headertext {
  /* border: 2px var(--font); */
  font-weight: 1000;
  color: var(--font);
  cursor: pointer;
  margin: 0;
}

.text {
  padding-bottom: 3%;
  color: var(--background);
  cursor: pointer;

}

.popup-link {
  text-decoration: none;
  color: var(--background);
  /* font-weight: bold; */

}


.headertext2 {
  margin-left: 1vw;
  border: 2px var(--font);
  font-weight: 1000;
  color: var(--background);
}



.topbar {
  max-width: 100%;
  height: auto;
  background-color: var(--background);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  bottom: 0px;


}

.logo1 {
  height: 5rem;
  max-width: 5rem;
  position: relative;
  left: 1%;
}

.personicon {
  position: relative;
  height: 1.8rem;
  cursor: pointer;
  border-radius: 50%;
}

.login {
  color: var(--font) !important;
}

.signup {
  color: white !important;
  background-color: var(--font) !important;
}

.rqotp {
  color: white !important;
  background-color: var(--font) !important;
}

.linkRoute {
  text-decoration: none;
}

.ub-mt_12vmin {
  margin-top: 5vmin !important;
  height: auto;
  width: 45rem !important;
}

.ub-dspl_flex {
  z-index: 9999 !important;
  border-radius: 0 !important;
}

/* responsive */

@media screen and (max-width:1192px) {
  .popup1 {
    width: 21%;
  }
}

@media screen and (max-width:1148px) {
  .popup1 {
    width: 30%;
  }
}

@media screen and (max-width:600px) {
  .popup1 {
    width: 40%;
  }
}

@media screen and (max-width:456px) {
  .popup1 {
    width: 50%;
  }

  .qrscan {
    display: flex;
  }
}

@media screen and (max-width:375px) {
  .popup1 {
    width: 60%;
  }
}