/* Form container */
.form-container {
  width: 80%;
  max-width: 500px;
  margin: 5% auto;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: var(--font);
}

.step1form {
  display: flex;
  justify-content: center;
}

.search-customer-label {
  padding-inline: 0.2rem;
}

.select-fields {
  height: 2.3rem !important;
}

.selectpurpose:focus {
  border: 1px solid var(--font);
}

.formsite {
  width: 42%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step1form-1 {
  display: flex;
  justify-content: space-around;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 10%;
  padding-right: 15%;
}

.step1form-2 {
  display: flex;
  justify-content: space-between;
  padding-top: 2%;
  padding-bottom: 2%;
  gap: 2rem;
}

/* Input container */
.input-container {
  display: flex;
  justify-content: space-between;

  width: 100%;
  flex-direction: column;
}

/* Center align */
.center-align {
  text-align: center;
}

/* Search Customer label */
.label-search-customer {
  margin-right: 10px;
}

/* Select purpose and add customer button container */
.select-and-add-container {
  display: flex;
  justify-content: space-between;
  padding-left: 30%;
  padding-right: 30%;
}

/* Add customer button */
.add-customer-button {
  height: 2rem;
  width: 2rem;
  font-size: 1.5rem;
  background-color: var(--font);
  color: var(--background);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

/* Selected customer name input */
.customer-name-input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--font);
  border-radius: 5px;
}

/* Select purpose dropdown */
.select-purpose-dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--font);
  border-radius: 5px;
}

/* Date of valuation input */
.date-of-valuation-input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--font);
  border-radius: 5px;
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 0.8rem;
}

/* Save button style */
.cus-det-save {
  padding: 10px 20px;
  color: var(--background);
  background-color: var(--font);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 500ms ease;
}

.cus-det-save:hover {
  background: var(--hover);
}

.addcustomer {
  height: 2rem;
  width: 2rem;
  font-size: 1.5rem;
  background-color: var(--font);
  color: var(--background);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: 500ms ease;
  position: relative;
  right: 0%;
  /* padding-bottom: 3%; */
}

.addcustomer:hover {
  background-color: var(--hover);
}

/* .addcustomer-mobile {
  display: none;
} */

/* Styles for the "Add New Customer" form */
.ub-w_620px {
  width: 489px !important;
}

.formdetails {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  color: var(--font);
  width: 90%;
  margin: auto
}

h3 {
  color: var(--font);
  margin-top: 5%;
}

h3 p {
  margin: unset;
}

.form-group-1 {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}

.forminput {
  padding: 10px 0;
  padding-left: 2%;
  border: 0.1px solid #927b7b;
  border-radius: 5px;
}


.forminput:focus {
  border: 1px solid var(--font);
}



.button-container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}


.textarea-input {
  font-family: "Arial";
  border-radius: 5px;
  min-height: 60px;
  padding: 0;
}

.textarea-input::placeholder {
  font-family: "Arial";

  padding-left: 1%;
  padding-top: 1%;
}

.textarea-input:focus {

  border: 1px solid green !important;

}

.textarea {
  height: 100px;
  /* Set the desired height for the textarea */
  resize: vertical;
  /* Allow vertical resizing for the textarea */
}

.errorcss {
  color: red;
  font-size: 0.8rem;
  line-height: normal;
  /* text-align: center; */
}

.center {
  text-align: center;
  margin-top: 20px;
}


.btnstep1 {
  margin-top: 2%;
  padding: 10px 20px;
  color: var(--background);
  background-color: var(--font);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease;

}

.btnstep1:hover {
  background-color: var(--hover);

}

.CustoButton {
  display: flex;
  justify-content: center;
  width: 100%;
}

.save-button {
  background-color: var(--font);
  color: var(--background);
  border: 0;
  border-radius: 5px;
  padding: 10px;
  margin-block: 2%;
  cursor: pointer;
  transition: 500ms ease;
}

.save-button:hover {

  background-color: var(--hover);
}

/* @media screen and (max-width:1440px){
  .step1form{
    padding-top: 2%;
  }

  .step1form-2{
    justify-content: space-around;
  }
  .addcustomer{
    margin-top: 2%;
  }
} */
/* responsive width 1024px */
@media only screen and (max-width: 1024px) {

  .formsite {
    width: 70%;
  }

  .step1form-1 {
    padding-right: 0;
    justify-content: unset;
    padding-left: 20%;
    gap: 1rem;
  }

  .addcustomer {
    margin-top: 1%;
  }
}

/* responsive width 768px */
@media only screen and (max-width: 768px) {

  .formsite {
    width: 80%;
  }

  .step1form {
    padding-top: 3%;
  }

  .step1form-1 {
    padding-right: 10%;
    padding-left: 0;
    gap: 2rem;
  }

  .step1form-2 {
    width: 100%
  }

  .step1form-2:nth-child(2) {
    gap: 2rem;
  }

  .step1form-2:nth-child(1) {
    gap: 0.2rem;
  }

  /* .select-fields {
    width: 15.5rem;
  } */

  .formsite {
    align-items: center;
    padding-left: 0px;
    width: 50%;
  }

  .addcustomer {
    margin-top: 2%;
  }

  .save-button {
    background-color: var(--font);
    color: var(--background);
    border: 0;
    border-radius: 5px;
    padding: 10px;
    margin-block: 2%;
    cursor: pointer;
    transition: 500ms ease;
  }
}

@media only screen and (max-width: 700px) {
  .ub-w_620px {
    width: 250px !important;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
  }

  .form-group div {
    width: 100%;
  }

  .formsite {
    width: 100%;
  }

  .step1form-1 {
    display: flex;
    justify-content: space-between;
    padding-top: 5%;
    padding-bottom: 2%;
    padding-left: 0%;
    padding-right: 0%;
  }

  .step1form-2 {
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    padding-top: 2%;
    padding-bottom: 2%;
  }


}

/* responsive width 425px */
@media only screen and (max-width: 425px) {


  .formsite {
    padding-block: 5%;
    width: 90% !important;
    gap: 1rem;
  }

  .cus-det-save {
    width: 30%;
  }

  .step1form-1 {
    width: 100%;
    gap: 0.2rem;
    flex-flow: column-reverse;
    align-items: center;
  }

  .step1form-1 center div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .save-button {
    width: 20%;
    margin: 0;
  }



  .forminput {
    width: 100%;
  }

  .textarea-input {
    width: 100%;
  }

  .cus-det-save {
    margin-top: 1rem;
  }

  .step1form-2 {
    justify-content: center;
    align-items: center;
    padding: unset;
    gap: 1rem !important;
  }

  .step1form-2:nth-child(2) {
    padding-bottom: 2%;
  }

  .select-fields {
    /* width: 15.3rem !important; */
  }

  .addcustomer-mobile {
    display: block;
    outline: 0;
    border: 0;
    padding: 10px 0;
    border-radius: 5px;
    background: var(--font);
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    width: 40%;

  }
}


@media screen and (max-width: 320px) {
  .addcustomer-mobile {
    margin-top: 2%;
    width: 50%;
  }

  .step1form-2 {
    gap: 0.3rem;
  }

  .step1form-2:nth-child(2) {
    padding-bottom: 2%;
  }

  .cus-det-save {
    width: 50%;
  }

}