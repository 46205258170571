.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ad-banner-header{
  width: 728px;
  height: 60px; 
  margin: 0 30px; 
  border: 1px solid #ccc; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.mainpage{
  padding-bottom: 5%;
}

@media (max-width: 800px) {
  .mainpage{
    padding-bottom: 20%;
  }
  
}


@media (max-width: 500px) {
  .mainpage{
    padding-bottom: 30%;
  }
  
}



@media (max-width: 425px) {
  .ad-banner-header {
      display: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.isInline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.isInline-1 {
  display: flex;
  justify-content: center;
  gap: 10%;
}

.App-header {
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  width: 100%; 
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  color: var(--font);
  z-index: 9999; 
}


.MuiInput-root {
  border: 1px solid #afafaf;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 0 !important;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}

.datepicker-input .MuiInput-root input,
.datepicker-input.form-group .MuiInputBase-input {
  padding-left: 2px;
}

.MuiInputBase-input.Mui-disabled[name=datepicker] {
  color: #000 !important;
}

.MuiInput-root input,
.MuiInputBase-input {
  padding: 10px 12px;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 2rem;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.MuiInputBase-input.Mui-disabled[name=datepicker] {
  color: hsl(0, 0%, 20%) !important;
  border-bottom: none
}

.MuiInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: transparent !important;
}

.MuiInput-root {
  border: 1px solid var(--font) !important;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 0 !important;
  height: 2.4rem;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: none;
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: none;
  border-bottom: none !important;
  pointer-events: none;
}

.MuiIconButton-root {
  background-color: transparent !important;
  color: var(--font);
  border: none;
  /* padding: 0% 3% !important; */
  margin-top: 0% !important;
}

.MuiSvgIcon-root {
  fill: var(--font);
  color: var(--font);
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.MuiPickersToolbar-toolbar {
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--font) !important;
}

.MuiPickersDay-daySelected {
  color: var(--font) !important;
  font-weight: 500;
  background-color: var(--font);
  border: 1px solid var(--font) !important;
}

.MuiPickersDay-current {
  color: var(--font) !important;
  font-weight: 600;
}

.MuiTablePagination-root {
  overflow: hidden !important;
}

.MuiTablePagination-root:last-child {
  padding: 0;
  height: max-content !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiInput-root input,
.MuiInputBase-input {
  border-bottom: none !important;
}

.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
  z-index: 0 !important;
}

.MuiTableCell-root {
  padding: 14px 0px !important;
}

.MuiTableSortLabel-icon {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.MuiTableCell-alignLeft {
  text-align: center !important;
}

.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700 !important;
  line-height: 1.5rem;
  color: var(--font) !important;
}

.MuiTableBody-root {
  color: var(--font) !important;
}

.MuiInputLabel-shrink {
  transform: none !important;
  transform-origin: 0% !important;
}

.MuiInputLabel-formControl {
  position: static !important;
}

.MuiFormControl-root {
  display: flex !important;
}