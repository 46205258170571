.HomesectionA {
  display: flex;
  justify-content: space-between;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  display: flex;
}

.cont {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2px;
  /* justify-content: space-between; */
  justify-content: center;
}

input {
  outline: none;
  border: none;
  padding-left: 2%;
  background: transparent;
}

.gen-btn {
  padding: 5%;
  margin-top: 3rem;
  margin-right: 7rem;
  background-color: var(--font);
  color: var(--background);
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
}


.f8 {
  color: var(--font);
  margin-top: 5%;
  margin-bottom: 1%;
}

.Apart1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
  /* gap:3px; */
}

.Apart1 div p {
  color: var(--font) !important;
}

.secBhead center h3 {
  text-align: center !important;
}

.secBhead center p {
  text-align: center !important;
}

.inputfields {
  outline: none;
  height: 1.5rem;
  width: 45%;
  text-align: center;
  border: none;
  background-color: var(--background);
  /* border-radius: 6px; */
  /* border: 1px solid  */
}

.Apart2 {
  padding-top: 5%;
  padding-left: 40%;
  width: 50%;
}

.savepdfbtn {
  color: var(--background);
  background-color: var(--font);
  border: none;
  border-radius: 5px;
  height: 2rem;
  width: 6rem;
  margin: 5%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  cursor: pointer;
}

.savepdfbtn:hover {
  background-color: var(--hover);
}

.HomepagesectionB {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  border: 2px solid var(--font);
  color: var(--font);
}

.secBhead {
  font-size: 1.6rem;
  color: var(--font);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3% 6%;
}

.valuation-table thead th {
  background-color: var(--font);
  color: var(--background);
  border: 1px solid var(--grey);
}

.valuation-table td {
  border: 1px solid var(--grey);
}

.report-table th {
  text-align: center !important;
}

.report-table th,
b,
td {
  color: var(--font);

}



.report-table td {
  padding: 1%;
  height: 1.1rem;
}

.report-table p {
  margin: 0;
  text-align: center !important;
}

.table {
  font-size: 1rem;
}

/* .tablecontainer2 table td,
th {
  text-align: center !important;
} */
/* #content {
  height: fit-content;
  width: 1350px;
} */
table {
  border-collapse: collapse;
}

thead {
  text-transform: uppercase;

  /* background-color: var(--font); */
  color: var(--background);
  border: 1px solid var(--font);
}

tr:nth-child(odd) {
  background-color: var(--background);
}

th {
  border: 1px solid var(--font);
  text-align: left;
  padding: 8px;
  height: 1.2rem;
}

/* .name-th{
  text-align: left !important;
} */

td {
  border: 1px solid var(--font);
  text-align: left;
  padding: 5px;
  height: 1.2rem;
}

.RVJbottom {
  display: flex;
  justify-content: space-between;
}

/* .tr-1 th{
  border: 1px solid var(--grey);
} */

.num-placeholder::placeholder {
  font-size: 1rem;
}

.name-th {
  text-align: left;
}

.real-jewel-table tr,
th,
td {
  border: 1px solid var(--grey);
}

.real-jewel-table {
  border-collapse: collapse;
}

.imitation-table tr,
th,
td {
  border: 1px solid var(--grey);
}

@media screen and (max-width: 1048px) {
  .Apart1 {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
  }

  .cont {
    gap: 0px;
    justify-content: flex-start;
  }

  .HomepagesectionB {
    padding-left: 1%;
    padding-right: 1%;
  }

}

@media screen and (max-width: 686px) {
  .Apart1 {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 10px;
    justify-content: space-between;
  }

  .Apart1 .cont:nth-child(3) {
    grid-column: span 3;
    justify-content: center;
  }

  .inputfields {
    width: min-content;
  }
}

@media screen and (max-width: 658px) {
  .Apart1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 56%;
    padding-left: 22%;
    gap: 10px;
  }

  .TableDescription {
    width: 100%;
    overflow: auto;
  }

  .Apart1 .cont:nth-child(1) {
    grid-column: span 3;
    justify-content: space-between;
  }

  .Apart1 .cont:nth-child(2) {
    grid-column: span 3;
    justify-content: space-between;
  }

  .Apart1 .cont:nth-child(3) {
    grid-column: span 3;
    justify-content: space-between;
  }

  .inputfields {
    width: max-content;
  }

  .cont {
    gap: 10px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 589px) {
  .Apart1 {
    width: 65%;
    padding-left: 18%
  }
}

@media screen and (max-width: 508px) {
  .Apart1 {
    width: 100%;
    padding-left: 0%;
  }
}

@media screen and (max-width: 331px) {
  .Apart1 {
    font-size: 14px;
  }
}