.MuiStepIcon-root.MuiStepIcon-completed {
    color: var(--font) !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
    color: var(--font) !important;
}

    /* width: 100% !important; */
    /* display: flex !important; */
    /* justify-content: center !important; */
    /* margin-left: 400px; */
    /* position: relative; */
    /* left: 40rem; */

    .navigation-button-container{
        display: flex;
        justify-content: center;
        margin-bottom: 8%;
    }
    .prev{
        font-size: 1rem;
        cursor: pointer;
        margin-bottom: 2vh;
    }
    .next{
        margin-left: 1vw;
        font-size: 1rem;
        cursor: pointer;
        margin-bottom: 2vh;
    }

    .button-container{
        display: flex;
        flex-direction: row;
    }


    @media screen and (max-width:425px){
        .stepper{
            padding:0 !important;
            margin-block:1%;
            padding-inline: 2% !important;
        }

        .stepper-step{
            /* padding-right: 0 !important; */
            /* padding-top:5% !important;
            padding-left: 25px !important; */
            padding: 0  !important;

        }
    }