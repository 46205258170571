.form-control {
    padding: 3.5% 2%;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid var(--font);
    padding-left: 2%;
    max-width: 100%;
}

.form-control::placeholder{
    font-size: 0.9rem;    
}

.form-control:focus {
    border: 1px solid var(--font) !important; 
    font-size: 1rem;
}

/* @media screen and (max-width: 425px) {
    .form-control{
        width: unset;
    }
} */