.profilemain {
    display: flex;
    padding-top: 6%;
    width: 100%;
    height: auto;    
    padding-bottom: 6%;
    justify-content: center;
    position: relative;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sectionA {
    width: 50%;
    height: 100%;
}

.profilehead {
    text-transform: uppercase;
    color: var(--font);
    text-align: center;
}

.profile-container{
    justify-content: space-around;
    padding-bottom: 2%;
}

.comp2profile {
    display: flex;
    justify-content: space-between;
}

.profilepara {
    padding: 2%;
}

.imagevaluation {
    height: 50%;
    width: 50%;
    padding-top: 5%;
    padding-bottom: 5%;
}


.sectionB {
    width: 50%;
    height: 30%;
    border: 1px var(--font);
    padding:1%;
    box-shadow: rgb(187, 187, 187) 0px 7px 29px 10px;    
}

.img-container{
    width: 22%;
    margin-right: 7%;
}

.imgg{
    width: 100%;
}

.user-details h4{
    color: var(--font);
}


@media screen and (max-width:768px) {
    .profilemain{
        padding-top: 8%;
    }

    .sectionB{
        width: 80%;
    }
}


@media screen and (max-width:600px) {
    .profilemain {
        display: flex;
        flex-direction: column-reverse;
        padding: 2%;
        width: 100%;
        height: auto;

        position: relative;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    .profilemain{
        padding:unset;
        padding-block: 10%;
    }

    .profile-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sectionB{
        margin: auto;
    }

    .img-container{
        margin: unset;
    }

    h4{
        text-align:left;
    }

   
    
}


@media screen and (max-width:425px) {
    .profilemain{
        padding-top: 10%;
    }

    .sectionB{
        flex-direction: column;
    }

}

@media screen and (max-width:320px) {

    .profilemain{
        padding-top: 10%;
    }
    h4{
        text-align: left;
        font-size: 15px;
    }
}
