.inr {
  font-family: "Urbanist", sans-serif;
  font-size: 1.6rem;
}

.f-card-button1 {
  display: flex;
  color: #6c7d02;
  border: 1px solid #6c7d02;
  border-radius: 5px;
  width: 80%;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.f-card-button1-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.f-card-button1:hover {
  background-color: #6c7d02;
  color: white;
}

.fourth-heading {
  font-family: Quicksand;
  font-size: 3rem;
}

.price {
  font-family: "Urbanist", sans-serif;
  font-size: 3rem;
}

.subscription-cards {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.subscription-card {
  padding: 20px;
  margin: 10px;
  /* text-align: center; */
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 30%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.firstcard1 {
  display: flex;
  justify-content: space-between;
}

.fourth-text {
  font-family: "Urbanist", sans-serif;
  font-size: 1.2rem;
}

.switch-container {
  display: inline-block;
}

.switch-input {
  display: none;
}

.switch-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
}

.switch-label:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3.5px;
  width: 20px;
  height: 20px;
  background-color: #ff5555;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.switch-label .switch-text {
  flex: 1;
  text-align: center;
}

.switch-input:checked + .switch-label:before {
  transform: translateX(22.5px);
}

.switch-text {
  color: #333;
}

.switch-text:first-child {
  margin-left: 5px;
}

.switch-text:last-child {
  margin-right: 5px;
}
.option {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.switch_ {
  /* width: 200px; */
  margin-right: 50px;
}

.switch_ main {
  display: flex;
  align-items: center;
  background: #6c7d02;
  padding: 5px;
  border-radius: 8px;
}

.switch_ ._label {
  margin: 0 7px;
  color: #f3f3f3;
  font-size: 15px;
  letter-spacing: 0.5px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .subscription-card {
    width: 47%;
  }
}

@media (max-width: 480px) {
  .subscription-card {
    width: 100%;
  }

  .option {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
  }
}
