.container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.class1 {
  font-family: Quicksand;
  font-size: 3rem;
}

.button1 {
  display: flex;
  color: #6c7d02;
  border: 1px solid #6c7d02;
  border-radius: 5px;
  width: 20%;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.button1-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.button1:hover {
  background-color: #6c7d02;
  color: white;
}

.fourth-heading {
  font-family: Quicksand;
  font-size: 3rem;
  color: #4caf50;
}
