h1 {
    color: var(--font);
    z-index: 1;
}

.login-form p {
    color: var(--font) !important;
}

.signup-form p {
    color: var(--font) !important;

}

.question {
    margin: 0px 0px;
    font-size: 14px;
}

.Resgisterheadline {
    padding: 6% 12%;
    text-align: end;
    margin: 0px 20px;
    width: 166px;
    animation: moveRightToLeft 1s linear 1;
    color: white;
    background: var(--font);
    border: none;
    border-radius: 5px;
    /* display: table-header-group; */
    cursor: pointer;
}

/* florals */
.floral-img-1 {
    position: absolute;
    left: 0;
    top: 0;
}

.floral-img-2 {
    position: absolute;
    right: 0;
    top: 0;
}

.floral-img-3 {
    position: absolute;
    top: 0;
    left: 0;
}

.floral-img-4 {
    position: absolute;
    top: 0;
    right: 0;

}

.ellipse-img {
    position: absolute;
    top: 16%;
    left: 51%;
}

.ellipse-img-2 {
    position: absolute;
    top: 78%;
    left: 58%;
    width: 2%;
}

.ellipse-img-3 {
    position: absolute;
    top: 11.3%;
    left: 52%;
}

.ellipse-img-4 {
    position: absolute;
    top: 89%;
    left: 59%;
    width: 2%;
}

label {
    color: var(--font);
}

/* main */
.loginmain {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 5% 0%;
    line-height: 30px;
    position: relative;
}

.signupmain {
    max-width: 100%;
    display: flex;
    padding-top: 2%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    line-height: 30px;
    position: relative;
    margin-bottom: 5%;
}

.login-form,
.signup-form {
    display: flex;
    flex-direction: column;
    width: 46%;
}


.form-group {
    display: flex;
    flex-direction: column;
}


.errorcss {
    color: red;
}

.btn {
    height: 2.5rem;
    width: 26%;
    font-size: 1rem;
    border: none;
    /* border-radius: 5px; */
    cursor: pointer;
    margin-top: 8%;
}

.btn:hover {
    background-color: var(--hover);
}

.btn-primary {
    color: var(--background);
    background-color: var(--font);
    border-radius: 8px;
}

.agreement {
    display: flex;
    flex-direction: column;
    width: 50%;
    line-height: 25px;
    color: var(--font);
}


/* responsive max-width:1024 */
@media screen and (max-width: 1024px) {
    .signupmain {
        padding-top: 5%;
    }

    .ellipse-img {
        top: 15%;
        left: 51%
    }

    .ellipse-img-3 {
        top: 16%;
    }

    .floral-img-1 {
        top: 10%;
    }

    .floral-img-2 {
        top: 7%;
    }

    .floral-img-3 {
        top: 15%;
    }

    .floral-img-4 {
        top: 15%;
    }

    .signup-form {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
}

@media (min-width: 960px) {
    .MuiGrid-grid-md-6 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media screen and (max-width: 960px) {
    .ellipse-img-3 {
        top: 9%;
    }
}

/* responsive max-width:768 */
@media screen and (max-width: 768px) {
    .signupmain {
        padding-top: 0%;
    }

    .ellipse-img-3 {
        top: 2%;
        left: 53%;
    }

    .floral-img-1 {
        width: 10rem;
        top: 10%;
    }

    .floral-img-2 {
        width: 10rem;
    }

    .floral-img-3 {
        width: 10rem;
    }

    .floral-img-4 {
        width: 10rem;
    }



    .ellipse-img-3 {
        top: 5%;
    }

    .loginmain {
        padding: 5% 0;
    }

    .ellipse-img {
        top: 12%;
        left: 52%;
    }

    .login-form,
    .signup-form {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        width: 39%;
        padding-bottom: 10%;
    }

}


/* responsive max-width:425px */
@media screen and (max-width: 425px) {
    .form-group {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
    }

    .login-form,
    .signup-form {
        width: 80%;
    }

    .signupmain {
        padding-block: 12%;
    }

    .ellipse-img {
        top: 8%;
        left: 53%;
    }

    .ellipse-img-3 {
        left: 56%;
        top: 9%;
    }

    .floral-img-1,
    .floral-img-2 {
        width: 5rem;
    }

    .floral-img-1 {
        top: 68%
    }

    .floral-img-3 {
        width: 6rem;
        top: 88%;

    }

    .floral-img-4 {
        width: 6rem;
        top: 0
    }

    .ellipse-img {
        top: 17%;
    }

    .loginmain {
        padding: 17% 0;
    }

    .login-form p {
        margin: unset
    }

    .signup-form p {
        margin-top: 0;
    }

    .agreement {
        display: flex;
        flex-direction: column;
        width: 90%;
        line-height: 25px;
        color: var(--font);
    }
}

@media screen and (max-width: 375px) {
    .floral-img-1 {
        top: 72%;
    }

    .floral-img-2 {
        top: 0;
    }

    .floral-img-3 {
        width: 5rem;
    }

    .floral-img-4 {
        width: 5rem;
    }

    .ellipse-img {
        top: 16%;
    }
}

@media screen and (max-width:320px) {
    .ellipse-img {
        top: 14%;
    }

    .Resgisterheadline {
        padding:11px 29px;
        margin: 0px 0px;
    }

    .signupmain {
        padding-block: 7% 0%;
    }

    .floral-img-2 {
        top: 5%;
    }

    .ellipse-img-3 {
        left: 57%;
        top: 8%;
    }

    .floral-img-1,
    .floral-img-2 {
        width: 4rem;
    }

    .floral-img-1 {
        top: 73%;
    }

    .floral-img-3,
    .floral-img-4 {
        width: 4rem;
        top: 2%;
    }

    .floral-img-3 {
        top: 92%;
    }
}